import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade, Lazy } from "swiper";
import SlideButton from "./Slide-Button";
import Timer from "./Timer";
import { Button, Modal } from "react-bootstrap";
import banners from "../../JSON/data.json";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { Arrowrightw } from "../../icons";
import React, { useEffect, useState } from "react";
import { SPEEDSIZE_PREFIX } from "../../App";

var imgs = [];

function dateInBetween(banner) {
  return (
    new Date() >= new Date(banner.startDate) &&
    new Date() <= new Date(banner.endDate)
  );
}
function BannerFilter(banner) {
  if (!banner.scheduled) return banner;
  else {
    if (dateInBetween(banner)) return banner;
  }
}

function isLiveOnTv() {
  const curDate = new Date().toISOString();
  const zuluDate = new Date(curDate);

  // Check if it's either Wednesday or Friday
  if (zuluDate.getUTCDay() === 3 || zuluDate.getUTCDay() === 5) {
    // console.log(zuluDate.getUTCDay() === 3 ? "Wendsday" : "Friday");

    const hour = zuluDate.getUTCHours();
    if (hour === 7) {
      // Check minutes if between 0 to 59
      const minutes = zuluDate.getUTCMinutes();
      if (minutes >= 0 && minutes <= 59) {
        return true;
      }
    }
  }
  return false;
}

const CTA_DEFAULT_TEXT = "Regístrate";
const CTA_DEFAULT_URL =
  "https://www.codere.mx/registro-deportes?clientType=sportsbook";

function MySwiper({ device }) {
  // States
  const [swiper, setSwiper] = useState(null);

  const [currentTycType, setCurrentTycType] = useState(null);
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);
  const [ctaText, setCtaText] = useState(CTA_DEFAULT_TEXT);
  const [ctaLink, setCtaLink] = useState(CTA_DEFAULT_URL);

  const [showTimer, setShowTimer] = useState(false);

  if (!device) imgs = banners.desktop_slide_list.filter(BannerFilter);
  else imgs = banners.mobile_slide_list.filter(BannerFilter);

  useEffect(() => {
    if (swiper) {
      if (showTycModal) swiper.autoplay.pause();
    }
  }, [showTycModal]);

  const indexHandler = (swiper) => {
    if (!swiper) setSwiper(swiper);

    setShowTimer(imgs[swiper.realIndex].hasTimer && !isLiveOnTv());

    setCtaText(imgs[swiper.realIndex].ctaText || CTA_DEFAULT_TEXT);
    setCtaLink(imgs[swiper.realIndex].ctaURL || CTA_DEFAULT_URL);
    setCurrentTycType(imgs[swiper.realIndex].tycType || "");
    setCurrentBannerName(imgs[swiper.realIndex].name || "");
  };

  if (!device) imgs = banners.desktop_slide_list.filter(BannerFilter);
  else imgs = banners.mobile_slide_list.filter(BannerFilter);

  const modalGenerator = () => {
    return (
      <>
        <Modal
          centered
          show={showTycModal}
          onHide={() => setShowTycModal(false)}
          contentClassName="terms-tyc"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="text-center">
                <h5>Términos y condiciones</h5>
                <h5>{currentBannerName}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentTycType && tycGenerator(currentTycType)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowTycModal(false)}
              className="accept-btn"
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const tycGenerator = (type) => {
    return (
      <iframe
        className="tyc-iframe"
        src="https://www.codere.mx/library/tyc/tyc.html"
      />
    );
  };

  const imageHandler = (item) => {
    const x = isLiveOnTv();
    // console.log("live on tv? ", x);

    return item.hasTimer ? (x ? item.alterImage : item.image) : item.image;
  };

  return (
    <>
      {showTimer && <Timer device={device} />}
      <Swiper
        onSlideChange={(swiper) => indexHandler(swiper)}
        modules={[Pagination, EffectFade, Autoplay, Lazy]}
        pagination={{ clickable: true }}
        effect={"fade"}
        // lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
        autoplay={{ delay: 3250, disableOnInteraction: true }}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        init={false}
      >
        {imgs.map((item, index) => (
          <SwiperSlide key={index}>
            <a href={ctaLink}>
              <img
                width={device ? "300" : "400"}
                height={device ? "410" : "140"}
                src={`${SPEEDSIZE_PREFIX}/${imageHandler(item, index)}`}
                alt={item.name}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      {!device ? (
        <div className="regis-tyc m-auto">
          <Button
            className="central-regis gl-effect"
            href={ctaLink}
            rel="nofollow"
          >
            {ctaText}
            <Arrowrightw />
          </Button>
          {currentTycType && (
            <div
              className="tyc text-white"
              onClick={() => setShowTycModal(true)}
            >
              Términos y condiciones
            </div>
          )}
        </div>
      ) : (
        <div className="ReactSwipeButton mobile_regis">
          <SlideButton ctaText={ctaText} regis={ctaLink} />
          {currentTycType && (
            <div className="mob-tyc">
              <span className="tyc" onClick={() => setShowTycModal(true)}>
                Términos y condiciones
              </span>
            </div>
          )}
        </div>
      )}
      {modalGenerator()}
    </>
  );
}
export default MySwiper;
