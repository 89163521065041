import { Image, NavLink } from "react-bootstrap";
import { SPEEDSIZE_PREFIX } from "../App";

const Footer = ({ device }) => {
  const footerURLPrefix = `${SPEEDSIZE_PREFIX}/https://www.codere.mx/library/Footer`;

  const app = {
    url: "https://coderemx.onelink.me/pJc7?af_web_dp=https%3A%2F%2Fapps.apple.com%2Fmx%2Fapp%2Fcodere-apuestas-deportivas-mx%2Fid1185542560&af_xp=custom&pid=Inicio_MX&c=organic&af_dp=coderemxsb%3A%2F%2F",
    os: ["appstore.svg", "android.png"],
  };

  const socials = [
    { name: "facebook", url: "https://www.facebook.com/codereMX/" },
    { name: "instagram", url: "https://www.instagram.com/coderemx/" },
    { name: "twitter", url: "https://twitter.com/CodereMX" },
    {
      name: "whatsapp",
      url: "https://api.whatsapp.com/send/?phone=525536977637&amp;text=Hola%2C+necesito+ayuda+personalizada+de+atenci%C3%B3n+al+cliente.+%C2%A1Gracias%21&amp;type=phone_number&amp;app_absent=0",
    },
    { name: "contact", url: "https://www.codere.mx/ayuda/contacto" },
    { name: "telegram", url: "https://t.me/Coderemx_bot" },
  ];

  const footer = [
    {
      title: "sobre nosotros",
      itemList: [
        {
          name: "Términos y Condiciones",
          rel: "nofollow",
          url: "https://www.codere.mx/ayuda/terminos-y-condiciones",
        },
        {
          name: "Política de Privacidad y Cookies",
          rel: "nofollow",
          url: "https://www.codere.mx/ayuda/privacidad",
        },
        {
          name: "Juego Responsable",
          rel: "nofollow",
          url: "https://www.codere.mx/ayuda/responsabilidad-social",
        },
        {
          name: "Condiciones Club de Lealtad",
          url: "https://www.codere.mx/ayuda/tyc-clubcodere",
          rel: "nofollow",
        },
      ],
    },
    {
      title: "links destacados",
      itemList: [
        { name: "Casino", url: "https://www.codere.mx/casino" },
        {
          name: "Ruleta",
          url: "https://www.codere.mx/library/ruleta.html",
        },
        {
          name: "Blackjack",
          url: "https://www.codere.mx/library/blackjack.html",
        },
        {
          name: "Momios",
          rel: "nofollow",
          target: "_blank",
          url: "https://apuestas.codere.mx/es_MX",
        },
        {
          name: "Blog Codere",
          target: "_blank",
          url: "https://blog.codere.mx/",
        },
        {
          name: "Descarga App",
          url: "https://www.codere.mx/ayuda/apuesta-en-tu-movil",
        },
      ],
    },
    {
      title: "ayuda",
      itemList: [
        {
          name: "Preguntas Frecuentes",
          url: "https://www.codere.mx/ayuda/informacion",
        },
        {
          name: "Promociones",
          url: "https://www.codere.mx/promociones",
          rel: "nofollow",
        },
        {
          name: "Quejas y reclamaciones",
          url: "https://www.codere.mx/ayuda/quejas-y-reclamaciones",
          rel: "nofollow",
        },
        {
          name: "Protección de Datos Web",
          url: "https://www.codere.mx/ayuda/proteccion-datos-web",
          rel: "nofollow",
        },
        {
          name: "Tipos de apuestas",
          url: "https://www.codere.mx/ayuda/tipos-de-apuesta",
          rel: "nofollow",
        },
        {
          name: "Deposito Book",
          url: "https://www.codere.mx/ayuda/deposito-book",
          rel: "nofollow",
        },
      ],
    },
    {
      title: "",
      itemList: [
        {
          name: "¿Cómo apostar?",
          url: "https://www.codere.mx/ayuda/como-apostar",
          rel: "nofollow",
        },
        {
          name: "Retiro Online",
          url: "https://www.codere.mx/ayuda/retiro-online",
          rel: "nofollow",
        },
        {
          name: "Deposito Online",
          url: "https://www.codere.mx/ayuda/deposito-online",
          rel: "nofollow",
        },
        {
          name: "Contacto",
          url: "https://www.codere.mx/ayuda/contacto",
          rel: "nofollow",
        },
        {
          name: "apuestas@codere.mx",
          url: "mailto:apuestas@codere.mx",
          rel: "nofollow",
        },
        {
          name: "+52 55 3697 7637",
          url: "https://api.whatsapp.com/send/?phone=525536977637&text=Hola%2C+necesito+ayuda+personalizada+de+atenci%C3%B3n+al+cliente.+%C2%A1Gracias%21&type=phone_number&app_absent=0",
          rel: "nofollow",
        },
      ],
    },
  ];

  const payments = [
    "7-eleven",
    "astropay",
    "circulok",
    "localcodere",
    "mercado-pago",
    "otras-tarjetas",
    "oxxo-pay",
    "paynet",
    "paysafe-card",
    "spei-transferencia",
    "superama",
    "todito-cash",
    "visa-martercard",
    "walmart",
  ];

  const security = [
    {
      name: "threeLogosFooter",
      url: "http://www.juegosysorteos.gob.mx/es/Juegos_y_Sorteos/Atencion_al_Ludopata",
    },
    { name: "SSL-Secure-Connection" },
  ];
  return (
    <footer className="nb-footer mb-5">
      <div className="container">
        <div className="apps mt-4 text-center">
          {app.os.map((s, k) => (
            <a href={app.url} key={k}>
              <img src={`${footerURLPrefix}/${s}`} />
            </a>
          ))}
        </div>

        <div
          className={`d-flex ${
            device && "flex-column align-items-center text-center"
          } justify-content-around mt-5`}
        >
          {footer.map((section, i) => (
            <div className={`${device && "w-50"} mb-4 mb-s-2`} key={i}>
              <span className={section.title && "footer-title"}>
                {section.title.toUpperCase()}
              </span>
              <div className={`text-white div-${section.title}`}>
                {section.itemList.map((item, j) => (
                  <NavLink
                    className="hover-green footer-item d-block"
                    href={item.url}
                    rel={item.rel}
                    key={j + item.name}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="socials d-flex flex-wrap flex-row mw-50 justify-content-center gap-3 mb-4 mt-4">
          {socials.map((social) => (
            <div key={social.name}>
              <a href={social.url} rel="nofollow" target="_blank">
                <img
                  width={30}
                  height={30}
                  src={`${footerURLPrefix}/s-${social.name}.svg`}
                />
              </a>
            </div>
          ))}
        </div>

        <div
          style={{ width: device ? "100%" : "51%" }}
          className="d-flex flex-wrap align-items-center justify-content-center mt-5 mb-5 m-auto gap-2"
        >
          {payments.map((payment, k) => (
            <Image
              width={80}
              // height={"auto"}
              key={k}
              src={`${footerURLPrefix}/${payment}.webp`}
              alt={payment}
            />
          ))}
        </div>
      </div>

      <div className="d-flex gap-3 justify-content-center mt-4 security">
        {security.map((item, _) => (
          <NavLink
            key={item.name}
            as={item.url ? "a" : "span"}
            href={item.url || null}
          >
            <img height={70} src={`${footerURLPrefix}/${item.name}.png`} />
          </NavLink>
        ))}
      </div>

      <div id="legals" className="text-center mt-5">
        Los juegos con apuestas están prohibidos para menores de edad. Oficio
        número 2768, DGG/SP/442/1997, DGJS/DGAAD/DCRCA/1566/2018,
        DGJS/1018/2015, DGJS/234/2019 y DGJS/1427/2023. Libros Foráneos, S, A.
        de C.V. , Sociedad anónima de capital variable con domicilio en Avenida
        del Conscripto 311, colonia Lomas de Sotelo, Alcaldía de Miguel Hidalgo,
        C.P. 11200, Ciudad de México.
      </div>
    </footer>
  );
};

export default Footer;
